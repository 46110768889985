<template>
  <PanelBox title="Nieuwsbriefvoorkeuren">
    <div v-if="categories" >
      <span
        v-if="hasInvalidGdprStatus"
        class="tw-text-xs tw-mt-4 tw-block"
      >
        Dit contact heeft een <strong>oranje of rode GDPR-status</strong> en kan niet voor nieuwsbrieven worden ingeschreven.<br>
        Volg de gebruikelijke stappen om GDPR-toestemming te vragen aan het contact.
      </span>

      <template v-else>
        <FormulateInput
          v-model="selectAll"
          type="checkbox"
          name="select_all"
          label="Alle nieuwsbrieven selecteren"
        />
        <FormulateForm
          #default="{ isLoading }"
          name="subscriptionMediums"
          @submit="submit"
        >
          <div class="tw-mt-14 tw-mb-6 tw-grid md:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-16">
            <div
              v-for="category in categories"
              :key="category.id"
              class="tw-px-5 tw-relative tw-w-auto tw-border tw-border-gray-cc tw-rounded-md"
            >
              <img
                :src="category.icon"
                :alt="category.name"
                :title="category.name"
                class="tw-p-2 tw-h-20 tw-w-20 tw-bg-white tw-rounded-full tw-absolute tw-transform tw--translate-y-1/2 tw-text-center tw-text-xs tw-inline-block"
              />

              <div class="tw-my-10">
                <p class="tw-my-4 tw-text-gray-cc tw-tg-mobile-body md:tw-tg-desktop-body">
                  {{ category.description_nl }}
                </p>

                <FormulateInput
                  v-for="medium in category.mediums"
                  :key="medium.id"
                  v-model="subscribedMediums"
                  :value="medium.id"
                  type="checkbox"
                  name="medium_ids"
                  :label="medium.name_nl"
                  outer-class="tw-m-0"
                />
              </div>
            </div>
          </div>
          <FormulateErrors />
          <FormulateInput type="submit" :disabled="isLoading">
            <i
              :class="[
                'far tw-mr-2',
                isLoading ? 'fa-spinner-third animate-spin' : 'fa-paper-plane'
              ]"
            />
            Voorkeuren opslaan
          </FormulateInput>
        </FormulateForm>
        <button
          class="tw-text-xs tw-text-danger tw-mt-4 tw-py-1 tw-rounded tw-underline"
          @click="stopAllCommunication"
        >
          Klik hier om alle communicatie van Dewaele Vastgoedgroep te stoppen
        </button>
      </template>
    </div>
  </PanelBox>
</template>

<script>
import PanelBox from '@/components/iam/PanelBox'
import { getCategories, getMediumIds, getSubscribedMediums, stopCommunication, updateSubscribedMediums } from '@/services/contacts'
import { errorModal, questionModal, successModal } from '@/modalMessages'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Newsletters',
  components: {
    PanelBox
  },
  data () {
    return {
      categories: [],
      allMediumIds: [],
      subscribedMediums: [],
      selectAll: false
    }
  },
  watch: {
    selectAll (value) {
      this.subscribedMediums = value ? [...this.allMediumIds] : []
    },
    subscribedMediums (val) {
      // Only start watching the subscribedMediums when all possible mediums have been initiated
      if (this.allMediumIds.length) {
        if (!(val.length)) this.selectAll = false
        if (val.length === this.allMediumIds.length) this.selectAll = true
      }
    }
  },
  computed: {
    ...mapState('contacts', ['contact']),
    contactId () {
      return this.$route.params.id
    },
    hasInvalidGdprStatus () {
      return this.contact?.global_unsubscribe || !this.contact?.consent_asked
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('contacts', [
      'loadContactById'
    ]),
    async init () {
      try {
        const response = await Promise.all([
          this.loadCategories(),
          this.loadSubscribedMediums(),
          this.loadMediumIds()
        ])
        if (this.subscribedMediums.length === this.allMediumIds.length) this.selectAll = true
        return response
      } catch (error) {
        errorModal('Er ging iets mis')
      }
    },
    async loadMediumIds () {
      const response = await getMediumIds()
      this.allMediumIds = response.data
      return response
    },
    async loadCategories () {
      const response = await getCategories()
      this.categories = response.data.results
      return response
    },
    async loadSubscribedMediums () {
      const response = await getSubscribedMediums(this.contactId)
      this.subscribedMediums = response.data
      return response
    },
    async stopAllCommunication () {
      try {
        const result = await questionModal('Door de communicatie te stoppen wordt het contact voor alle nieuwsbrieven uitgeschreven, worden alle Hou-me-op-de-hoogtes stopgezet en krijgt het contact een rode GDPR-status.')
        if (!result.value) return
        const response = await stopCommunication(this.contactId)
        // Enforce reloading of Vuex stored contact so that GDPR is marked red
        // When the user switches to the General tab (without refreshing the page)
        this.loadContactById(this.contactId)
        return response
      } catch {
        errorModal('Er ging iets mis bij het stopzetten van alle communicatie')
      }
    },
    async update () {
      const response = await updateSubscribedMediums(this.contactId, { medium_ids: this.subscribedMediums })
      this.loadSubscribedMediums()
      return response
    },
    async submit (payload) {
      try {
        const response = await updateSubscribedMediums(
          this.contactId,
          payload
        )
        successModal('De nieuwsbriefvoorkeuren zijn succesvol opgeslagen')
        await this.loadSubscribedMediums()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'subscriptionMediums')
      }
    }
  }
}

</script>
